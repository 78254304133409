<template>
    <div id="user_list">
        <el-card>
            <div class="AdminTopOper">
                <el-space wrap :size="15">
                    <el-input v-model="pageData.searchKey" placeholder="请输入订单号"></el-input>
                    <el-input style="width: 120px" v-model="pageData.where.id" placeholder="请输入ID"></el-input>
                    <el-button type="primary" icon="Search" @click="orderSearce()">搜索</el-button>
                    <el-button type="primary" icon='Plus' @click="pageData.handleCreate()">新建</el-button>



                </el-space>
            </div>

            <!-- 表格主体 -->
            <el-table :data="pageData.rows">
                <el-table-column prop="id" label="编号" width="100" />
                <el-table-column label="资料名称" width="150">
                    <template #default="scope">
                        {{ scope.row.filename }}
                    </template>
                </el-table-column>
                <el-table-column label="打印价格" width="90">
                    <template #default="scope">
                        {{ scope.row.price }} 元
                    </template>
                </el-table-column>
                <el-table-column label="打印参数" width="150">
                    <template #default="scope">
                        {{ scope.row.printAtt }}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="文件地址" width="150" >
                    <template #default="scope">
                        {{ scope.row.imgUrl }}
                    </template>
                </el-table-column> -->
                <el-table-column label="资料描述" width="200">
                    <template #default="scope">
                        {{ scope.row.des }}
                    </template>
                </el-table-column>
                <AdminTableButton :width="300">
                    <template #default="scope">
                        <!-- <el-button @click="handleAdminCard(scope.row.id, scope.row.classifyId)" icon="Rank">详情</el-button> -->
                        <el-button @click="click_copyUrl(scope.row)" icon="Rank">复制链接</el-button>
                    </template>
                </AdminTableButton>
            </el-table>

            <AdminPagination> </AdminPagination>
        </el-card>



        <!-- 新建与编辑dialog -->
        <el-dialog v-model="pageData.dialogVisible" title="新建" width="800px">
            <el-form ref="form" :model="pageData.row" label-width="100px" size='medium'>
                <el-form-item label="资料名称">
                    <el-input v-model="pageData.row.filename"></el-input>
                </el-form-item>
                <el-form-item label="打印价格">
                    <el-input v-model="pageData.row.price"></el-input>
                </el-form-item>
                <el-form-item label="打印参数">
                    <el-input v-model="pageData.row.printAtt"></el-input>
                </el-form-item>
                <el-form-item label="纸张数量">
                    <el-input v-model="pageData.row.printAtt2"></el-input>
                </el-form-item>
                <el-form-item label="预览文件">
                    <!-- <el-input v-model="pageData.row.imgUrl"></el-input> -->
                    <el-upload class="avatar-uploader" :show-file-list="false" :http-request="httpRequestFile_pre">
                        <el-button type="primary">上传预览文件</el-button>
                    </el-upload>

                </el-form-item>
                <el-form-item label="原文件">
                    <!-- <el-input v-model="pageData.row.imgUrl"></el-input> -->
                    <el-upload class="avatar-uploader" :show-file-list="false" :http-request="httpRequestFile">
                        <el-button type="primary">上传原文件</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="资料主图以及详情图">
                    <!-- <el-input v-model="pageData.row.imgUrl"></el-input> -->
                    <el-upload class="avatar-uploader" :show-file-list="false" :http-request="httpRequestFile">
                        <img v-if="pageData.row.imgUrl" :src="pageData.row.imgUrl" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                    <span style="margin: 0px 8px;"></span>
                    <!-- <el-input v-model="pageData.row.imgUrl2"></el-input> -->
                    <el-upload class="avatar-uploader" :show-file-list="false" :http-request="httpRequestFile2">
                        <img v-if="pageData.row.imgUrl2" :src="pageData.row.imgUrl2" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                    <span style="margin: 0px 8px;"></span>
                    <el-upload class="avatar-uploader" :show-file-list="false" :http-request="httpRequestFile2">
                        <img v-if="pageData.row.imgUrl3" :src="pageData.row.imgUrl3" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                    <span style="margin: 0px 8px;"></span>
                    <el-upload class="avatar-uploader" :show-file-list="false" :http-request="httpRequestFile2">
                        <img v-if="pageData.row.imgUrl4" :src="pageData.row.imgUrl4" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </el-form-item>

                <el-form-item label="资料描述">
                    <el-input v-model="pageData.row.des"></el-input>
                </el-form-item>


            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="pageData.dialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="pageData.create()">Confirm</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
  
<script setup >
import { ref, onMounted, reactive } from 'vue'
import Api from '@/network'
import COS from 'cos-js-sdk-v5';

import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store'
const pageData = reactive(Api.adminPage('upFile'))
pageData.where.count = null
onMounted(pageData.getPage())

const cos = new COS({
    getAuthorization: async function (options, callback) {
        let res = await Api.request({
            url: '/getTcosTmpeKey?a='+new Date(),
            methds: 'post',
            data: {
                type: 'UpFile'
            }
        })
        console.log(res)
        let credentials = res.credentials
        callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            SecurityToken: credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: res.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: res.expiredTime, // 时间戳，单位秒，如：1580000000
        });

    }
});





const httpRequestFile = (RequestBody) => {
    let key = new Date().getTime() + RequestBody.file.name
    cos.putObject({
        Bucket: store.state.initData.upFile.Tcos.Bucket, /* 必须 */
        Region: store.state.initData.upFile.Tcos.Region,     /* 存储桶所在地域，必须字段 */
        Key: key,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: RequestBody.file, // 上传文件对象
        onProgress: function (progressData) {
            console.log(JSON.stringify(progressData));
        }
    }, function (err, data) {
        pageData.row.imgUrl = "https://" + data.Location
    })
}

const httpRequestFile2 = (RequestBody) => {
    let key = new Date().getTime() + RequestBody.file.name
    cos.putObject({
        Bucket: store.state.initData.upFile.Tcos.Bucket, /* 必须 */
        Region: store.state.initData.upFile.Tcos.Region,     /* 存储桶所在地域，必须字段 */
        Key: key,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: RequestBody.file, // 上传文件对象
        onProgress: function (progressData) {
            console.log(JSON.stringify(progressData));
        }
    }, function (err, data) {
        pageData.row.imgUrl2 = "https://" + data.Location
    })
}

const httpRequestFile3 = (RequestBody) => {
    let key = new Date().getTime() + RequestBody.file.name
    cos.putObject({
        Bucket: store.state.initData.upFile.Tcos.Bucket, /* 必须 */
        Region: store.state.initData.upFile.Tcos.Region,     /* 存储桶所在地域，必须字段 */
        Key: key,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: RequestBody.file, // 上传文件对象
        onProgress: function (progressData) {
            console.log(JSON.stringify(progressData));
        }
    }, function (err, data) {
        pageData.row.imgUrl3 = "https://" + data.Location
    })
}
const httpRequestFile4 = (RequestBody) => {
    let key = new Date().getTime() + RequestBody.file.name
    cos.putObject({
        Bucket: store.state.initData.upFile.Tcos.Bucket, /* 必须 */
        Region: store.state.initData.upFile.Tcos.Region,     /* 存储桶所在地域，必须字段 */
        Key: key,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: RequestBody.file, // 上传文件对象
        onProgress: function (progressData) {
            console.log(JSON.stringify(progressData));
        }
    }, function (err, data) {
        pageData.row.imgUrl4 = "https://" + data.Location
    })
}

const httpRequestFile_pre = (RequestBody) => {
    let key = new Date().getTime() + RequestBody.file.name
    cos.putObject({
        Bucket: store.state.initData.upFile.Tcos.Bucket, /* 必须 */
        Region: store.state.initData.upFile.Tcos.Region,     /* 存储桶所在地域，必须字段 */
        Key: key,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: RequestBody.file, // 上传文件对象
        onProgress: function (progressData) {
            console.log(JSON.stringify(progressData));
        }
    }, function (err, data) {
        pageData.row.preFileUrl = "https://" + data.Location
        ElMessage({
            message: '上传文件成功',
            type: 'success',
        })
    })
}

const httpRequestFile_ = (RequestBody) => {
    let key = new Date().getTime() + RequestBody.file.name
    cos.putObject({
        Bucket: store.state.initData.upFile.Tcos.Bucket, /* 必须 */
        Region: store.state.initData.upFile.Tcos.Region,     /* 存储桶所在地域，必须字段 */
        Key: key,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: RequestBody.file, // 上传文件对象
        onProgress: function (progressData) {
            console.log(JSON.stringify(progressData));
        }
    }, function (err, data) {
        pageData.row.fileUrl = "https://" + data.Location
        ElMessage({
            message: '上传文件成功',
            type: 'success',
        })
    })
}


</script>
  
  

<style lang="less">
.avatar-uploader .avatar {
    width: 120px;
    height: 120px;
    display: block;
}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    text-align: center;
}
</style>
  